<script>
// import LegoTooltip from '@/components/Lego/LegoTooltip.vue'

export default {
  components: {
    // LegoTooltip
  },
  props: {
    board: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  methods: {
    getRoleColor( role ) {
      if( role.level == 1 ) return'warning'
      else if( role.level == 2 ) return'danger'
      else if( role.level == 3 ) return'primary' 
      else if( role.level == 4 ) return'success'
    }
  },
  computed: {
    route() {
      if( this.board ) {
        return {
          name: 'Tablero',
          params: {
            boardId: this.board.id
          }
        }
      }
      else {
        return {
          name: 'CrearTablero'
        }
      }
    }
  }
}

</script>

<template>
  <router-link :to="route" class="tablero">
    <slot>
      <template v-if="board">
        <header class="header">
          <div>
            <span class="title">
              {{ board.title }}
            </span>
          </div>
          <!-- <LegoTooltip /> -->
        </header>
        <div class="body">
          <span class="description">
            {{ board.description }}
          </span>
        </div>
        <footer class="footer">
          <b-badge class="px-3" :variant="getRoleColor(board.roleInBoard)">
            {{ board.roleInBoard.name }}
          </b-badge>
          <div class="interactions">
            <span>{{ board.collaborators.length }}</span>
            <span>
              <i class="fa-regular fa-user fa-sm"></i>
            </span>
          </div>
        </footer>
      </template>
    </slot>
  </router-link>
</template>

<style scoped>
.tablero {
  min-height: calc(250px * 9 / 16);
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 0 0 4px 2px var( --lego-shadow);
  background-color: var(--lego-bg);
  transition: 0.5s;
  padding: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  transition: 0.3s;
}

.tablero:hover {
  transform: scale(1.02);
  box-shadow: 0 0 5px 2px var( --lego-shadow-hover);
}

.tablero .body {
  flex: 1;
  margin: .5rem 0;
}
.description {
  color: rgb(143, 143, 143);
}

.tablero .footer {
  display: flex;
  justify-content: space-between;
}

.interactions {
  display: flex;
  justify-content: center;
  gap: .3rem;
  align-items: center;
}

.title {
  font-size: 1.157rem;
  overflow-wrap: break-word;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: .5rem;
}
</style>